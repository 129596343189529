import { useEffect, useState } from 'react'

export function isMobile() {
    if (typeof window === 'undefined') return true
    const {isAndroid, isIPad13, isIPhone13, isWinPhone, isMobileSafari, isTablet } = require('react-device-detect')
    return (isAndroid || isIPad13 || isIPhone13 || isWinPhone || isMobileSafari || isTablet)
}

export default function useIsMobile() {
    const [isMobileDevice, setIsMobileDevice] = useState<boolean|null>(null)
    useEffect(() => {
        const { isAndroid, isIPad13, isIPhone13, isWinPhone, isMobileSafari, isTablet } = require('react-device-detect')
        setIsMobileDevice(isAndroid || isIPad13 || isIPhone13 || isWinPhone || isMobileSafari || isTablet)
    }, [])

    return isMobileDevice
}