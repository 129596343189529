import { enableStaticRendering } from "mobx-react-lite"
import { createContext, ReactNode, useContext } from "react"
import RootStore, { RootStoreHydration } from "./RootStore"
// there is no window object on the server
enableStaticRendering(typeof window === "undefined")

// local module level variable - holds singleton store
let store: RootStore

// function to initialize the store
function initializeStore(initialData?: RootStoreHydration): RootStore {
  const _store = store ?? new RootStore()

  // if there is data call the root store hydration method
  if (initialData) {
    _store.hydrate(initialData)
  }
  // For server side rendering always create a new store
  if (typeof window === "undefined") return _store

  // Create the store once in the client
  if (!store) store = _store

  return _store
}

// create the context
const StoreContext = createContext<RootStore | undefined>(undefined)

export function RootStoreProvider({
  children,
  hydrationData
}: {
  children: ReactNode
  hydrationData?: RootStoreHydration
}) {
  // pass the hydration data to the initialization function
  const store = initializeStore(hydrationData)

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

// create the hook
export function useStore() {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider")
  }

  return context
}
