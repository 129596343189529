import { makeObservable } from "mobx";
import AdStore from "./AdStore"
import { AdStoreHydration } from "./AdStore";

export type RootStoreHydration = {
    adStore?: AdStoreHydration;
};

export default class RootStore {
    adStore: AdStore

    constructor() {
        this.adStore = new AdStore(this)
    }

    hydrate(data: RootStoreHydration) {
        // check if there is data for this particular store
        if (data.adStore) {
            this.adStore.hydrate(data.adStore)
        }
    }

}
