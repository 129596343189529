export enum AdSizeList {
    x300_250,
    x300_600,
    x300_100,
    x120_600,
    x160_600,
    x728_90,
    x970_90,
    x970_150,
    x970_250,
    x1000_90,
    x1000_200,
    x1000_250,
    x1000_300,
    x300_125,
    x300_150,
    x336_280,
    x320_50,
    x320_100,
    x300_50
}
type AdSize = [number, number]

export const ADSIZES: Record<AdSizeList, AdSize> = {
    // MPU
    [AdSizeList.x300_250]: [300, 250],
    [AdSizeList.x300_600]: [300, 600],
    [AdSizeList.x300_100]: [300, 100],
    [AdSizeList.x120_600]: [120, 600],
    [AdSizeList.x160_600]: [160, 600],

    // BANNERS
    [AdSizeList.x728_90]: [728, 90],
    [AdSizeList.x970_90]: [970, 90],
    [AdSizeList.x970_150]: [970, 150],
    [AdSizeList.x970_250]: [970, 250],
    [AdSizeList.x1000_90]: [1000, 90],
    [AdSizeList.x1000_200]: [1000, 200],
    [AdSizeList.x1000_250]: [1000, 250],
    [AdSizeList.x1000_300]: [1000, 300],

    // SMALL
    [AdSizeList.x300_125]: [300, 125],
    [AdSizeList.x300_150]: [300, 150],

    // MOBILE
    [AdSizeList.x336_280]: [336, 280],
    [AdSizeList.x320_50]: [320, 50],
    [AdSizeList.x320_100]: [320, 100],
    [AdSizeList.x300_50]: [300, 50],
}

const BANNER_SIZES = [
    ADSIZES[AdSizeList.x728_90],
    ADSIZES[AdSizeList.x970_90],
    ADSIZES[AdSizeList.x970_150],
    ADSIZES[AdSizeList.x1000_90],
    ADSIZES[AdSizeList.x1000_200],
    ADSIZES[AdSizeList.x1000_250],
    ADSIZES[AdSizeList.x1000_300]
]

const MPU_SIZES = [
    ADSIZES[AdSizeList.x300_250],
    ADSIZES[AdSizeList.x300_600],
    ADSIZES[AdSizeList.x300_100],
    ADSIZES[AdSizeList.x120_600],
    ADSIZES[AdSizeList.x160_600],
]

const SMALL_SIZES = [
    ADSIZES[AdSizeList.x300_250],
    ADSIZES[AdSizeList.x300_150],
    ADSIZES[AdSizeList.x300_125],
    ADSIZES[AdSizeList.x300_100],
]
const MOBILE_SIZES = [
    ADSIZES[AdSizeList.x300_250],
    ADSIZES[AdSizeList.x300_600],
    ADSIZES[AdSizeList.x300_100],
    ADSIZES[AdSizeList.x300_50],
    ADSIZES[AdSizeList.x336_280],
    ADSIZES[AdSizeList.x320_50],
    ADSIZES[AdSizeList.x320_100],
]
const MOBILE_BANNER_SIZES = [
    ADSIZES[AdSizeList.x320_50],
    ADSIZES[AdSizeList.x320_100],
    ADSIZES[AdSizeList.x300_50],
    ADSIZES[AdSizeList.x300_100],
]

/** List of ad size, per type (column, banner, square...) */

type Size = [number, number]
export type SlotName = "fixed_banner" | "sticky" | "masthead_haut" | "mpu_haut" | "mpu_bas" | "small_1" | "small_2" | "small_3" | "small_4" | "small_5" | "small_6" | "long_7" | "small_8" | "long_9"

interface Slot {
    /** div id */
    name: SlotName
    /** position targeting value */
    pos: { mobile?: string, desktop?: string }
    /** list of sizes */
    sizes: { mobile?: Size[], desktop?: Size[] },
    /** placeholder size */
    placeholder: { desktop?: Size, mobile?: Size },
    /** is the slot lazy loaded ? */
    lazy: { mobile: boolean, desktop: boolean } | boolean
}

/** List of all slots from inventory */
export const SLOTS: Slot[] = [
    {
        name: "fixed_banner",
        pos: { mobile: "150" },
        sizes: { mobile: MOBILE_BANNER_SIZES },
        placeholder: { mobile: ADSIZES[AdSizeList.x300_100]},
        lazy: false
    },
    {
        name: "sticky",
        pos: { mobile: "151" },
        sizes: { mobile: MOBILE_BANNER_SIZES },
        placeholder: { mobile: ADSIZES[AdSizeList.x300_100]},
        lazy: false
    },
    {
        name: "masthead_haut",
        pos: { desktop: "1", mobile: "101" },
        sizes: { desktop: BANNER_SIZES, mobile: MOBILE_SIZES },
        placeholder: { desktop: ADSIZES[AdSizeList.x1000_300], mobile: ADSIZES[AdSizeList.x300_600]},
        lazy: false
    },
    {
        name: "mpu_haut",
        pos: { desktop: "2", mobile: "102" },
        sizes: { desktop: MPU_SIZES, mobile: MOBILE_SIZES },
        placeholder: { desktop: ADSIZES[AdSizeList.x300_600], mobile: ADSIZES[AdSizeList.x300_600]},
        lazy: false
    },
    {
        name: "mpu_bas",
        pos: { desktop: "3", mobile: "103" },
        sizes: { desktop: MPU_SIZES, mobile: MOBILE_SIZES },
        placeholder: { desktop: ADSIZES[AdSizeList.x300_600], mobile: ADSIZES[AdSizeList.x300_600]},
        lazy: false
    },
    {
        name: "small_1",
        pos: { desktop: "10", mobile: "101" },
        sizes: { desktop: SMALL_SIZES, mobile: MOBILE_SIZES },
        placeholder: { desktop: ADSIZES[AdSizeList.x336_280], mobile: ADSIZES[AdSizeList.x300_250]},
        lazy: false
    },
    {
        name: "small_2",
        pos: { desktop: "11", mobile: "102" },
        sizes: { desktop: SMALL_SIZES, mobile: MOBILE_SIZES },
        placeholder: { desktop: ADSIZES[AdSizeList.x336_280], mobile: ADSIZES[AdSizeList.x300_250]},
        lazy: false
    },

    {
        name: "small_3",
        pos: { desktop: "12", mobile: "103" },
        sizes: { desktop: SMALL_SIZES, mobile: MOBILE_SIZES },
        placeholder: { desktop: ADSIZES[AdSizeList.x336_280], mobile: ADSIZES[AdSizeList.x300_250]},
        lazy: false
    },
    
    {
        name: "small_4",
        pos: { desktop: "13", mobile: "104" },
        sizes: { desktop: SMALL_SIZES, mobile: MOBILE_SIZES },
        placeholder: { desktop: ADSIZES[AdSizeList.x336_280], mobile: ADSIZES[AdSizeList.x300_250]},
        lazy: false
    },

    {
        name: "small_5",
        pos: { mobile: "105" },
        sizes: { mobile: SMALL_SIZES },
        placeholder: { mobile: ADSIZES[AdSizeList.x300_250]},
        lazy: false
    },

    {
        name: "small_6",
        pos: { mobile: "106" },
        sizes: { mobile: SMALL_SIZES },
        placeholder: { mobile: ADSIZES[AdSizeList.x300_250]},
        lazy: false
    },

    {
        name: "long_7",
        pos: { mobile: "107" },
        sizes: { mobile: MOBILE_SIZES },
        placeholder: { mobile: ADSIZES[AdSizeList.x300_600]},
        lazy: false
    },

    {
        name: "small_8",
        pos: { mobile: "108" },
        sizes: { mobile: SMALL_SIZES },
        placeholder: { mobile: ADSIZES[AdSizeList.x300_250]},
        lazy: false
    },

    {
        name: "long_9",
        pos: { mobile: "109" },
        sizes: { mobile: MOBILE_SIZES },
        placeholder: { mobile: ADSIZES[AdSizeList.x300_600]},
        lazy: false
    }


    
]

