import { action, computed, makeObservable, observable, runInAction } from "mobx"
import { SlotName, SLOTS } from "../config/ads"
import { isMobile } from "../lib/mobile"
import RootStore from "./RootStore"
import debounce from "lodash/debounce"
import { ADUNIT } from "../config/partners"

export type AdStoreHydration = {}

interface slotInfo {
  gslot: any
  lastUpdate: Date | null
}

/** minimum delay between two ad refresh in seconds */
const AUTOREFRESH_DELAY = 5

/** if prebid does not reload in this time window, then use google directly */
const FAILSAFE_TIMEOUT = 3000

export default class AdStore {
  rootStore: RootStore
  @observable slots: Record<string, slotInfo> = {}
  @observable _isMobile: boolean | null = null
  @observable isEnabledOutbrain: boolean = false
  // is prebid correctly loaded ?
  @observable prebidLoaded: boolean = false

  // keep list of google ads slots to refresh to regroup call
  _refreshQueue: any[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    makeObservable(this)
  }

  hydrate(data?: AdStoreHydration) {
    if (data) {
      // nothing to hydrate for the moment
    }
    // init and base configuration of prebid / gpt
  }

  /** use cached value if available */
  @computed get isMobile() {
    if (!this._isMobile) this._isMobile = isMobile()
    return this._isMobile
  }

  /** define a new slot */
  @action addSlot(slotId: SlotName, divId: string) {
    window.googletag.cmd.push(() => {
      const slotData = SLOTS.find((slot) => slot.name === slotId)
      if (!slotData) {
        console.error(`can't find slot with id ${slotId}.`)
        return
      }
      if (this.slots[divId]) {
        console.warn(`Slot ${slotId} already defined.`)
        return
      }
      // console.log(`define slot pos : ${slotData.pos}, slotId : ${slotData.name}`)

      const sizes = this.isMobile ? slotData.sizes["mobile"] : slotData.sizes["desktop"]
      const pos = this.isMobile ? slotData.pos["mobile"] : slotData.pos["desktop"]

      // console.log(ADUNIT, sizes, divId, pos)
      const gslot = window.googletag
        .defineSlot(ADUNIT, sizes, divId)
        .setTargeting("pos", pos)
        .addService(window.googletag.pubads())

      runInAction(() => {
        // store slot in the store
        this.slots[divId] = { gslot: gslot, lastUpdate: null }
      })
    })
  }

  /** Reloads ads from divs ids */
  @action refreshAds(divIds: string[]) {
    window.googletag.cmd.push(() => {
      const now = new Date().getTime()
      // get slots to update
      // only get not refreshed in the last MINIMUM_DELAY seconds
      const gslots = divIds
        .filter(
          (divId) =>
            divId in this.slots &&
            (this.slots[divId].lastUpdate === null ||
              (now - this.slots[divId].lastUpdate!.getTime()) / 1000 > AUTOREFRESH_DELAY)
        )
        .map((divId) => {
          // set slot refresh date as now
          this.slots[divId].lastUpdate = new Date()
          return this.slots[divId].gslot
        })
      // divIds.filter((divId) => divId in this.slots).map((divId) => this.slots[divId])
      this._refreshQueue = [...this._refreshQueue, ...gslots]
      console.log(this._refreshQueue.map((slot) => slot.getSlotId().getDomId()))

      debounce(
        this._refreshFromQueue.bind(this)
      ,25)()
    })
  }

  /** Reloads ads from queue */
  @action _refreshFromQueue() {

    if (this._refreshQueue.length === 0) return

    window.reworldAd.que.push(() => {
      if(this._refreshQueue.length === 0)
        return
      window.reworldAd.refresh(this._refreshQueue)
      console.log(this._refreshQueue.map((slot) => slot.getSlotId().getDomId()))
      this._refreshQueue = []
      this.prebidLoaded = true
    })

    setTimeout(() => {
      if (!this.prebidLoaded) {
        window.googletag.cmd.push(() => {

          // RELOAD WITH GOOGLE WITHOUT PREBID
          window.googletag.pubads().refresh(this._refreshQueue);
          this._refreshQueue = []
        })
      }
    }, FAILSAFE_TIMEOUT)
  }

  /** destroy all slots. Called when page is changed */
  @action removeSlots() {
    if (window.googletag.destroySlots)
      window.googletag.destroySlots()
    this._refreshQueue = []
    this.slots = {}
  }

  @action resetSlotDate(divId:string) {
    if(divId in this.slots)
      this.slots[divId].lastUpdate = null
  }

  @action disableOutbrain() {
    this.isEnabledOutbrain = false
  }
  @action enableOutbrain() {
    this.isEnabledOutbrain = true
  }
}
