import "../styles/globals.css"
import type { AppProps } from "next/app"
import { RootStoreProvider } from "../store"
import { useEffect, useState } from "react"

declare global {
  interface Window {
      googletag: any;
      reworldAd: any;
      gtag: any;
      dataLayer: any;
  }
}

function MyApp({ Component, pageProps }: AppProps) {

  return (
    <RootStoreProvider hydrationData={pageProps.hydrationData}>
      <Component {...pageProps} />
    </RootStoreProvider>
  )
}
export default MyApp
